<template>
  <b-row>
    <b-col cols="12">
      <b>{{ product.name }}</b> <br>
      <b-badge v-if="product.brand">{{ product.brand.name }}</b-badge>
      <br>
      barkod: {{ product.barcode }} ({{ product.id }})
    </b-col>
  </b-row>

</template>

<script>
export default {
  name: "StocktakingProductDetail",
  props: {
    product: {
      type: Object,
      required: true,
    }
  }
}
</script>
<style scoped>

</style>