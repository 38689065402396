<template>
  <b-card>
    <h3>Eski Raf
      <span v-if="_oldRack"> {{ _oldRack.rack }} ({{ _oldRack.id }})</span>
    </h3>
    <b-row>
      <b-col cols="6">
        <v-select
            label="rack"
            :options="whereIsLeaf"
            :reduce="item => item.id"
            class="select-size-xs"
            placeholder="Stok Rafı Seçiniz"
            @option:selected="selectOldRack"
        />
      </b-col>
      <b-col cols="6">
        <b-button-group>
          <b-button variant="primary" :disabled="!(_oldRack && _oldRack.id)" @click="getOldRackProducts">
            Getir
          </b-button>
          <b-button
              variant="success"
              v-if="_oldRack && _newRack && _stocktakingType && _oldRack.stocktaking_status===0"
              @click="putStockRack(1)">
            Başlat
          </b-button>
          <b-button
              variant="success"
              v-if="_oldRack && _newRack && _stocktakingType && _oldRack.stocktaking_status===2"
              @click="putStockRack(1)">
            Yeniden Başlat
          </b-button>
          <b-button
              variant="warning"
              v-if="_oldRack && _oldRack.stocktaking_status===1"
              @click="putStockRack(2)">
            Ara Ver
          </b-button>
          <b-button variant="danger" v-if="_oldRack && _oldRack.stocktaking_status===1" @click="putStockRack(3)">
            Bitir
          </b-button>
          <b-button variant="info" v-if="_oldRack && _oldRack.stocktaking_status===2" disabled>Ara Verilmiş</b-button>
          <b-button variant="info" v-if="_oldRack && _oldRack.stocktaking_status===3" disabled>Bitmiş</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import axios from "@/libs/axios";

export default {
  name: "OldRack",
  components: {vSelect},
  data: () => ({}),
  computed: {
    ...mapGetters('stockRack', ['_whereIsLeaf']),
    ...mapGetters('stocktakingV2', ["_oldRack", "_newRack", "_stocktakingType"]),
    whereIsLeaf() {
      return this._whereIsLeaf.filter(leaf => leaf.rack.indexOf('ERS') > -1)
      return this._whereIsLeaf.filter(leaf => leaf.rack.indexOf('ERS') > -1 && leaf.stocktaking_status !== 3)
    }
  },
  methods: {
    putStockRack(stocktaking_status) {
      this.$store.dispatch('stockRack/putStockRack', {
        id: this._oldRack.id,
        stocktaking_status
      })
          .then(res => {
            if (res.status === 'success') {
              this.$store.commit('stocktakingV2/setOldRack', res.content)
              this.$store.dispatch('stocktakingV2/getOldRackProducts', this._oldRack.id)
            }
          })
    },
    selectOldRack(rack) {
      this.$store.commit('stocktakingV2/setOldRack', rack)
    },
    getOldRackProducts() {
      this.$store.dispatch('stocktakingV2/getOldRackProducts', this._oldRack.id)
    }
  }
}
</script>

<style scoped>

</style>