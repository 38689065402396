<template>
  <b-card>
    <b-row>
      <b-col cols="1">
        <StocktakingProductImage :images="product.images"/>
      </b-col>

      <b-col cols="3">
        <StocktakingProductDetail :product="product"/>
      </b-col>

      <!-- Eski Stokların Listesi -->
      <b-col cols="4">
        <b-row class="border bg-info text-white text-xl-left rounded">
          <b-col cols="2"><b> Durum</b></b-col>
          <b-col cols="2"><b> Tedarikçi</b></b-col>
          <b-col cols="2"><b> Stok/iade</b></b-col>
          <b-col cols="2"><b> Ekleyen</b></b-col>
          <b-col cols="2"><b> Raf/Stok</b></b-col>
          <b-col cols="2"><b>Fiyat</b></b-col>
        </b-row>
        <StocktakingProductOldStock
            v-for="stock in product.stocktaking_old_stocks"
            :key="stock.id"
            :stock="stock"
        />
      </b-col>
      <b-col cols="4">
        <b-row class="border bg-success text-white text-xl-left rounded">
          <b-col cols="2"><b> Durum</b></b-col>
          <b-col cols="2"><b> Tedarikçi</b></b-col>
          <b-col cols="2"><b> Stok/iade</b></b-col>
          <b-col cols="2"><b> Ekleyen</b></b-col>
          <b-col cols="2"><b> Raf/Stok</b></b-col>
          <b-col cols="2"><b>Fiyat</b></b-col>
        </b-row>
        <StocktakingProductOldStock
            v-for="stock in product.stocktaking_new_stocks"
            :key="stock.id"
            :stock="stock"
        />
      </b-col>
      <!--
      <b-col cols="2">
        <b-row class="border bg-success text-white text-xl-left rounded">
          <b-col cols="12"><b>Sayım Stok</b></b-col>
        </b-row>
        <b-row class="border rounded">
          <b-col cols="12">
            <b>{{ product.new_stock }}</b>
          </b-col>
        </b-row>
      </b-col>
      -->
    </b-row>
  </b-card>
</template>


<script>
import StocktakingProductImage from "@/views/v2/stock/stocktaking/StocktakingProductImage.vue";
import StocktakingProductDetail from "@/views/v2/stock/stocktaking/StocktakingProductDetail.vue";
import StocktakingProductOldStock from "@/views/v2/stock/stocktaking/StocktakingProductOldStock.vue";

export default {
  name: "StocktakingProduct",
  components: {StocktakingProductOldStock, StocktakingProductDetail, StocktakingProductImage},
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>