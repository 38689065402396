<template>
  <b-row class="border rounded mb-3">
    <b-col cols="12">
      <b-row>
        <b-col cols="2">
          <b-badge :variant="stock.status_detail.status_color">{{ stock.status_detail.status }}</b-badge>
        </b-col>
        <b-col cols="2">
          <b-badge>{{ stock.supplier.name }}</b-badge>
        </b-col>
        <b-col cols="2">
          <b-badge>{{ stock.stock_return.name }}</b-badge>
        </b-col>
        <b-col cols="2">
          <b-badge>{{ stock.add_user.short_name }}</b-badge>
        </b-col>
        <b-col cols="2">
          <b-badge variant="warning">{{ stock.stock_rack.rack }}</b-badge>
          <b-badge variant="danger">{{ stock.quantity }}</b-badge>
        </b-col>
        <b-col cols="2">
          <b-badge variant="secondary">{{ stock.price }}</b-badge>
        </b-col>
      </b-row>
      <b-row v-if="isVisible">
        <b-col cols="12">
          <SupplierMatching :stock="stock" :isVisible="isVisible"/>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
import SupplierMatching from "@/views/v2/stock/SupplierMatching.vue";

export default {
  name: "StocktakingProductOldStock",
  components: {SupplierMatching},
  props: {
    stock: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>

</style>