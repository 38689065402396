<template>
  <section>
    <b-row>
      <b-col cols="6">
        <OldRack/>
      </b-col>
      <b-col cols="6">
        <NewRack/>
      </b-col>
    </b-row>

    <StocktakingProduct
        v-for="(product,index) in _products"
        :product="product"
        :key="index"
    />
  </section>
</template>

<script>
import OldRack from "@/views/v2/stock/stocktaking/OldRack.vue";
import NewRack from "@/views/v2/stock/stocktaking/NewRack.vue";
import {mapGetters} from "vuex";
import StocktakingProduct from "@/views/v2/stock/stocktaking/StocktakingProduct.vue";

export default {
  name: "StocktakingHome",
  components: {StocktakingProduct, OldRack, NewRack},
  computed: {
    ...mapGetters('stocktakingV2', ['_products']),
  },
  mounted() {
    this.$store.dispatch('stockRack/getWhereIsLeaf');
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>