<template>
  <b-card>
    <h3>Yeni Raf
      <span v-if="_newRack"> {{ _newRack.rack }} ({{ _newRack.id }})</span></h3>
    <b-row>
      <b-col cols="5">
        <v-select
            label="rack"
            :options="whereIsLeaf"
            :reduce="item => item.id"
            class="select-size-xs"
            placeholder="Stok Rafı Seçiniz"
            @option:selected="getNewRackProducts"
        />
      </b-col>
      <b-col cols="2">
        <b-form-radio
            v-model="$store.state.stocktakingV2.stocktakingType"
            name="stocktakingtype"
            value="partial"
            disabled
        >Parçalı
        </b-form-radio>
        <b-form-radio
            v-model="$store.state.stocktakingV2.stocktakingType"
            name="stocktakingtype"
            value="full">Tümü
        </b-form-radio>
      </b-col>
      <b-col cols="5">
        <b-form-input
            type="text"
            placeholder="Barkod Ara"

            @keyup.enter="addProductByBarcode"
            :disabled="isSearchBarcode"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import log from "echarts/src/scale/Log";

export default {
  name: "NewRack",
  components: {vSelect},
  data: () => ({}),
  computed: {
    ...mapGetters('stockRack', ['_whereIsLeaf']),
    ...mapGetters('stocktakingV2', ['_oldRack', '_products', '_newRack', '_newRackProducts', '_stocktakingType']),
    whereIsLeaf() {
      return this._whereIsLeaf.filter(leaf => leaf.rack.indexOf('ERS') < 0)
    },
    isSearchBarcode() {
      return !(this._oldRack && this._newRack && this._stocktakingType && this._oldRack.stocktaking_status === 1 && this._products.length > 0)
    }
  },
  methods: {
    getNewRackProducts(rack) {
      this.$store.commit('stocktakingV2/setNewRack', rack)
    },
    addProductByBarcode($event) {
      if (!this._newRack) {
        alert('Yeni Raf Seçilmemiş')
        return;
      }

      let searchBarcode = $event.target.value;
      $event.target.value = ''

      let newStock = null;
      let selectedProduct = null;
      this._products.forEach(product => {
        if (product.barcode === searchBarcode) {
          selectedProduct = product;
          product.stocktaking_new_stocks.forEach(stocktakingNewStock => {
            if (stocktakingNewStock.rack_id === this._newRack.id && stocktakingNewStock.added_channel === this._stocktakingType) {
              newStock = stocktakingNewStock;
            }
          })
        }
      });
      //buradan devam etmeliyim

      if (newStock) {
        this.$store.dispatch('stocktakingV2/updateNewProducts', {
          id: newStock.id,
          quantity: 1
        })
            .then(res => {
              if (res.status === 'success') {
                newStock.quantity++;
              }
            });
      } else {
        this.$store.dispatch('stocktakingV2/storeNewProducts', {
          searchBarcode,
          new_rack_id: this._newRack.id,
          old_rack_id: this._oldRack.id,
          quantity: 1,
          stocktaking_type: this._stocktakingType,
        })
            .then(res => {
              if (res.status === 'success') {
                //selectedProduct.stocktaking_new_stocks.push(res.content)
              }
            })
      }
    }
  }
}
</script>

<style scoped>

</style>