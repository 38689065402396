<template>
  <b-row>
    <b-col cols="12">
      <div class="imageContainer m-0 p-0" v-if="images">
        <div class="clearfix border" v-if="images[0]">
          <b-img class="border border-primary" :src="'https://api.kitapfirsatlari.com/' + images[0].url"
                 width="150"/>
        </div>
      </div>
    </b-col>
  </b-row>

</template>

<script>
export default {
  name: "StocktakingProductImage",
  props: {
    images: {
      type: Array,
      default: [],
    }
  }
}
</script>


<style scoped>

</style>